import React from 'react';
import { Container } from 'react-bootstrap';
import EarnSection from '../../components/earnSection/EarnSection';
import './Earn.scss';

export const Earn = (props) => {
  return (
    <Container className="text-center RootEarn" id="que-conseguire">
      <div className="RootEarn-inside h-100" style={{ zIndex: '90' }}>
        <EarnSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Earn;
