import React from 'react';
import { Container } from 'react-bootstrap';
import AboutUsSection from '../../components/aboutUsSection/AboutUsSection';
import './AboutUs.scss';

export const AboutUs = (props) => {
  return (
    <Container className="text-center RootAboutUs">
      <div className="RootAboutUs-inside h-100" style={{ zIndex: '90' }}>
        <AboutUsSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default AboutUs;
