import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Parallax } from 'react-scroll-parallax';
import { isMobile } from '../../../../layout/Layout';

const JoinUsSection = () => {
  const mobile = isMobile();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://suprive40873.activehosted.com/f/embed.php?id=142"';
    script.async = true;
    document.body.appendChild(script);

    // const activeForms = document.getElementsByClassName('_form_142');
    // const myForm = activeForms[0];
    // myForm.addEventListener('submit', sendGtagConversionEvent); // GTM ON SUBMIT EVENT

    return () => {
      // myForm.removeEventListener('submit', sendGtagConversionEvent); // GTM ON SUBMIT REMOVE EVENT
    };
  }, []);

  return (
    <Row className="joinUsSectionRow h-100 justify-content-center align-items-center">
      <Col xs={12} lg={6} className="joinUsText">
        <Parallax
          className="custom-class"
          x={[-20, 0]}
          // y={[50, 0]}
          tagOuter="figure"
          disabled={mobile}
        >
          <div className="joinUsContent joinUsTextCol">
            <h3 className="h2Title" style={{ textAlign: 'left' }}>
              <span className="boldText h2Title">Únete</span> a nuestro
              <br />
              MOVIMIENTO
            </h3>
            <p className="pText" style={{ textAlign: 'left' }}>
              Empieza a recibir los últimos pasos, consejos y noticias sobre
              este apasionante baile.
            </p>
          </div>
          <img
            alt=""
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-offset="-400"
            className="joinUsImg d-flex d-lg-none"
            src={require('../../../../assets/img/photos/9---Twerkealo-min-min.png')}
          ></img>
          <div className="_form_142"></div>
        </Parallax>
      </Col>
      <Col
        xs={12}
        lg={5}
        className="joinUsContent imageCol"
        md={{ offset: '1' }}
      >
        <Parallax
          className="custom-class"
          x={[0, -20]}
          // y={[0, -50]}
          tagOuter="figure"
          disabled={mobile}
        >
          <img
            alt=""
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-offset="-400"
            className="joinUsImg d-none d-lg-flex"
            src={require('../../../../assets/img/photos/9---Twerkealo-min-min.png')}
          ></img>
        </Parallax>
      </Col>
    </Row>
  );
};

export default JoinUsSection;
