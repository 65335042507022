import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const TimeLine = () => {
  return (
    <>
      <VerticalTimeline className="verticalTimeLineStyle d-none d-lg-block">
        <VerticalTimelineElement
          className="vertical-timeline-element--work rightFloating"
          date="25%"
        >
          <p className="pText">
            Tonificar (mucho) tus piernas y sobretodo tu culo.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work rightFloating"
          date="50%"
        >
          <p className="pText">
            Sentirte feliz, empoderada y segura al mismo tiempo.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work rightFloating"
          date="75%"
        >
          <p className="pText">
            Dominar un tipo de baile moderno, sensual y muy muy creativo🔥🔥.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work rightFloating"
          date="100%"
        >
          <p className="pText">
            Expresar tus sentimientos a través del baile y dejar boquiabierto a
            todo el que mire tus increíbles pasos
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      <VerticalTimeline className="verticalTimeLineStyle d-block d-lg-none">
        <VerticalTimelineElement className="vertical-timeline-element--work rightFloating">
          <p className="pText">
            Tonificar (mucho) tus piernas y sobretodo tu culo.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement className="vertical-timeline-element--work rightFloating">
          <p className="pText">
            Sentirte feliz, empoderada y segura al mismo tiempo.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement className="vertical-timeline-element--work rightFloating">
          <p className="pText">
            Dominar un tipo de baile moderno, sensual y muy muy creativo🔥🔥.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement className="vertical-timeline-element--work rightFloating">
          <p className="pText">
            Expresar tus sentimientos a través del baile y dejar boquiabierto a
            todo el que mire tus increíbles pasos
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </>
  );
};

export default TimeLine;
