import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Parallax } from 'react-scroll-parallax';
import { isMobile } from '../../../../layout/Layout';

const AboutUsSection = () => {
  const mobile = isMobile();

  return (
    <Row className="AboutUsSectionRow h-100 justify-content-center align-items-center">
      <Col xs={12} lg={5} className="aboutUsCol">
        <Parallax
          className="custom-class"
          x={[-20, 0]}
          tagOuter="figure"
          disabled={mobile}
        >
          <h2 className="h2Title aboutUsTitle d-inline d-lg-none">
            ¿quiénes somos?
          </h2>
          <div data-aos="fade-right" data-aos-duration="1000">
            <img
              alt=""
              className="aboutUsImg aboutUsOlivia"
              src={require('../../../../assets/img/photos/6---Twerkealo-min-min.png')}
            ></img>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <h2 className="namesTitle">Olivia Misssy</h2>
            <p className="pText">
              Desde muy pequeña me han interesado los ritmos modernos, movidos,
              latinos y todo lo que me haga mover. Hice clases de Twerk por un
              tiempo y me enamoré por completo cuando me ayudó a echar mis
              miedos y vergüenza a un lado.
              <br />
              <br />
              Durante varios años me he dedicado al mundo de la noche como gogo.
              <br />
              <br />
              Mi trayecto en el mundo de la noche me ha llevado a aprender la
              soltura de las caderas, y como dominar mi culo para bailar
              cualquier ritmo.
              <br />
              <br />
              En este programa, yo te ayudaré con la parte práctica aportando mi
              estilo y aprendiendo a la vez que tú de la técnica profesional de
              Lucia.
            </p>
          </div>
          <div className="whiteLine whiteLineOlivia" />
        </Parallax>
      </Col>
      <Col xs={12} lg={(5, { offset: '2' })} className="aboutUsCol">
        <Parallax
          className="custom-class"
          x={[0, -20]}
          tagOuter="figure"
          disabled={mobile}
        >
          <h2
            className="h2Title aboutUsTitle d-none d-lg-inline"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            ¿quiénes somos?
          </h2>
          <div data-aos="fade-left" data-aos-duration="1000">
            <img
              alt=""
              className="aboutUsImg aboutUsLucia"
              src={require('../../../../assets/img/photos/7---Twerkealo-min-min.png')}
            ></img>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <h2 className="namesTitle">Lucía Muzo</h2>
            <p className="pText">
              Soy Profesora de Twerk, desde hace 6 años imparto clases para todo
              tipo de nivel. Mis inicios fueron en la rama de Dance Hall.
              <br />
              <br />
              Al ser un estilo de tanto movimiento de caderas, logré la soltura
              necesaria y el dominio del ritmo que requiere el Twerk, que cuando
              lo conocí, me fascinó inmediatamente. Su cultura, lo sensual que
              es y lo que lograba comunicar cuando lo practicaba.
              <br />
              <br />
              Desde ese momento supe que era lo que quería hacer. A partir de
              allí me especialicé en este estilo de baile y he podido trabajar
              con artistas españoles internacionales.
            </p>
          </div>
          <div className="whiteLine whiteLineLucia" />
        </Parallax>
      </Col>
    </Row>
  );
};

export default AboutUsSection;
