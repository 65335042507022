import React from 'react';
import { Container } from 'react-bootstrap';
import UniqueSection from '../../components/uniqueSection/UniqueSection';
import './Unique.scss';

export const Unique = (props) => {
  return (
    <Container className="text-center RootUnique" id="beneficios">
      <div className="RootUnique-inside h-100" style={{ zIndex: '90' }}>
        <UniqueSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Unique;
