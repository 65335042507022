import React, { useState } from 'react';
import {
  Accordion,
  Card,
  Container,
  Row,
  Col,
  ListGroup,
  Button,
} from 'react-bootstrap';
import './FaqSection.scss';

import { Parallax } from 'react-scroll-parallax';
import { isMobile } from '../../../../layout/Layout';

import CustomToggle from './components/CustomToggle';

export const FaqSection = () => {
  const [activeEventKey, setActiveEventKey] = useState('0');

  const updateActiveEventKey = (newEventKey) => {
    setActiveEventKey(newEventKey);
  };

  const mobile = isMobile();

  return (
    <Container fluid className="faqContainer">
      <Row
        className="faqSectionRow"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Col
          className="faqCol col-12 col-lg-12"
          // data-aos="fade-left"
          // data-aos-duration="1000"
        >
          <Parallax
            className="custom-class"
            y={[-20, 20]}
            tagOuter="figure"
            disabled={mobile}
          >
            <ListGroup>
              <ListGroup.Item className="faqTitleBackground">
                <div className="contentFaq">
                  <h1 className="h2Title faqTitle titleLeftBorder">
                    <p className="h2Title">
                      PREGUNTAS
                      <br />
                      FRECUENTES <span className="boldText">(FAQ)</span>
                    </p>
                  </h1>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Parallax>
        </Col>
        <Col
          className="faqCol col-12 col-lg-12 faqColFirst"
          // data-aos="fade-left"
          // data-aos-duration="1000"
        >
          <Accordion className="cardFaqContent" defaultActiveKey="0">
            <Row className="accordionRow">
              <Col xs={12} lg={6} xl={5} className="faqAccFirstCol">
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="0"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Cuánto dura el curso?"
                  />
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      No hay límites ni duración específica. Todo depende de ti
                      y de la velocidad que lo hagas. Está diseñado para que
                      aprendas y practiques cuanto quieras hasta que te sientas
                      segura de pasar al siguiente módulo. Una vez terminado,
                      podrás repetirlo cuanto desees y perfeccionar lo
                      aprendido.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="1"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="Si tengo un Nivel CERO de baile y Twerk, ¿podré hacerlo?"
                  />
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      ¡Por supuesto que sí! Enseñaremos desde lo más básico
                      hasta lo más avanzado. Te llevaremos de 0 a 100 en el
                      Twerk. Lo mejor de todo es que tú decidirás el ritmo y
                      cuándo avanzar de nivel. Repite las lecciones tanto como
                      quieras hasta que domines este arte por completo.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="2"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Me servirá el curso para sí ya he hecho Twerk y lo he practicado antes?"
                  />
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      Claro que sí. Por eso hemos diseñado un programa que va
                      desde lo más básico hasta lo más avanzado. Habrán pasos
                      inéditos y coreografías originales, así que lo básico te
                      servirá para perfeccionar la técnica y lo avanzado para
                      profesionalizarte y llevar la sensualidad a tope.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="3"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Si nunca he bailado, puedo hacerlo?"
                  />
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      Totalmente, puedes hacerlo sin importar el nivel en el que
                      te encuentres. Aquí te enseñaremos cómo hacerlo y a
                      entenderlo para que aprendas a sentir la música. Al final
                      todo es práctica y el Twerk no es la excepción. Aquí nos
                      olvidamos de vergüenza, prejuicios y tabúes, si sigues
                      todos los consejos y practicas, seguramente muy pronto
                      serás la sensación en cualquier pista.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="4"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Será sólo baile o habrá teoría?"
                  />
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      Hay un Módulo introductorio donde aprenderás lo básico del
                      Twerk y de donde viene. Como toda rama del baile, tiene
                      una historia que contar.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="5"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Cuántos pagos tendré que hacer?"
                  />
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      ¡Es un pago Único! Tendrás acceso ilimitado y de por vida.
                      Si hay actualizaciones, también podrás acceder a ella de
                      forma gratuita.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="6"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Hay horas predeterminadas para las clases?"
                  />
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      No. Es un curso que puedes hacer a tu ritmo, a la hora que
                      quieras, en el momento que quieras. Está pensado para que
                      cualquier persona pueda hacerlo sin obstáculos ni
                      barreras.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="7"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Puedo hacerme daño haciendo Twerk?"
                  />
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      Como todo estilo de baile, puede servir como
                      ejercicio/cardio. Te recomendamos calentar y estirar tus
                      músculos para evitar daños, sin embargo te garantizamos
                      que sudarás y pondrás tu corazón a correr. Tal cual una
                      clase de baile en el gym.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
              </Col>
              <Col xs={12} lg={6} xl={5}>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="8"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Cómo puedo pagar Twerkealo?"
                  />
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>
                      Podrás pagar a través de tarjeta de crédito/débito (VISA,
                      MASTERCARD, AMERICAN EXPRESS) y Paypal. Es una plataforma
                      de pago segura y encriptada con la que no corres riesgo
                      alguno.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="9"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Puedo hacerlo desde mi país/ ciudad?"
                  />
                  <Accordion.Collapse eventKey="9">
                    <Card.Body>
                      Claro que sí. Twerkealo no tiene restricciones de horario
                      ni ubicación, podrás hacerlo desde donde te encuentres.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="10"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Qué necesito para poder hacer el curso y conseguir resultados?"
                  />
                  <Accordion.Collapse eventKey="10">
                    <Card.Body>
                      Solo necesitas un móvil o un ordenador y conexión a
                      internet para poder ver el curso. Además, lo puedes hacer
                      tanto desde tu casa, como al aire libre o cualquier lugar
                      que prefieras.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="11"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Puedo compartir mi cuenta?"
                  />
                  <Accordion.Collapse eventKey="11">
                    <Card.Body>
                      No. La academia está diseñada para que tu acceso no se
                      pueda compartir. Te recomendamos no intentarlo y así
                      evitarás tener problemas al iniciar sesión. El curso es de
                      uso individual y está prohibido compartir, distribuir o
                      comercializar este curso.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="12"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="Si no me gusta el curso, ¿puedo pedir el reembolso de mi dinero?"
                  />
                  <Accordion.Collapse eventKey="12">
                    <Card.Body>
                      Tenemos la política de reembolso del 100% sin preguntarte
                      nada dentro de los primeros 30 días, siempre y cuando
                      hayas hecho menos del 30% del curso y no hayas intentado
                      descargar el contenido del Programa. Si consideras que no
                      es lo que buscabas o no te ha gustado, te devolveremos tu
                      dinero.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="13"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Cómo puedo entrar al curso online?"
                  />
                  <Accordion.Collapse eventKey="13">
                    <Card.Body>
                      Para entrar al curso tienes que hacer click arriba a la
                      derecha en “Mis Cursos” y te llevará a la página para
                      poner tu usuario y contraseña.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
                <Card
                  className="faqCardsAccordion"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <CustomToggle
                    eventKey="14"
                    activeEventKey={activeEventKey}
                    updateEventKey={updateActiveEventKey}
                    faqTitle="¿Puedo ver los videos varias veces?"
                  />
                  <Accordion.Collapse eventKey="14">
                    <Card.Body>
                      Sí, tienes acceso ilimitado a todo el contenido del curso.
                      Puedes repetirlo tantas veces como quieras.
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="bottomLine" />
                </Card>
              </Col>
              <Col xs={12}>
                <Card className="buttonCard">
                  <Button
                    href="#inscribirse"
                    bsPrefix
                    className="buttonsFaq buttonImage"
                  >
                    <span className="buttonTitle">Acceder al Curso</span>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default FaqSection;
