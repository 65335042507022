import React, { useEffect } from 'react';
import { Jumbotron, Button, Row, Col, Container } from 'react-bootstrap';
import './GridHome.scss';

import { Parallax } from 'react-scroll-parallax';

import { isMobile } from '../../../../layout/Layout';

// import hoverEffect from 'hover-effect';

// import Smooth from '../../../../components/skewScroll/SkewScroll';

export const GridHome = (props) => {
  const mobile = isMobile();

  return (
    <Container fluid>
      <Row className="rowHome">
        <Col
          xs={12}
          lg={4}
          className="homeCol imgHomeCol"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <Parallax
            className="custom-class d-none d-lg-block"
            x={[-20, 20]}
            tagOuter="figure"
            disabled={mobile}
          >
            <div className="imgContainer">
              {/* <picture>
                <source srcset="/images/cereal-box.webp" />
              </picture> */}
              <img
                className="imgOlivia"
                src={require('../../../../assets/img/photos/1---Twerkealo-min-min.png')}
                data-aos="zoom-in"
                data-aos-duration="1000"
                alt=""
              ></img>
            </div>
          </Parallax>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="homeCol homeColContent"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <Jumbotron className="jumbotronHome">
            <h1 className="h1Title">twerkealo</h1>
            <p className="pText">
              Descubre cómo Explotar tu Sensualidad y Convertirla en ARTE a
              través del Twerk, Incluso si crees que el Baile no es para ti.
            </p>
            <Row className="buttonplay justify-content-center">
              <Button
                href="#inscribirse"
                bsPrefix
                className="buttonText buttonImage"
                size="lg"
              >
                Únete a Twerkealo
              </Button>{' '}
              <Button
                href="#video"
                bsPrefix
                className="buttonText buttonImage2"
                size="lg"
                style={{ color: '#000' }}
              >
                Ver Video
              </Button>
              {/* <svg
                className="blob"
                viewBox="0 0 600 600"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(300,300)">
                  <path
                    d="M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z"
                    fill="#ce0000"
                  />
                </g>
              </svg>
              <FontAwesomeIcon className="playIconStyle" icon={faPlay} /> */}
            </Row>
          </Jumbotron>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="homeCol imgHomeCol"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <Parallax
            className="custom-class d-none d-lg-block"
            x={[20, -20]}
            tagOuter="figure"
            disabled={mobile}
          >
            <div className="imgContainer">
              <img
                className="imgLucia"
                src={require('../../../../assets/img/photos/2---Twerkealo-min-min.png')}
                data-aos="zoom-in"
                data-aos-duration="1000"
                alt=""
              ></img>
            </div>
          </Parallax>
          {/* <div className="imgContainer d-block d-lg-none">
            <img
              className="imgOlyLuci"
              src={require('../../../../assets/img/photos/10---Twerkealo-min-min-min.png')}
              data-aos="zoom-in"
              data-aos-duration="1000"
              alt=""
            ></img>
          </div> */}
        </Col>
      </Row>
      {/* <div className="videoHomePage d-flex d-lg-none">
            <ResponsiveEmbed aspectRatio="16by9">
               <embed
                  type="image/svg+xml"
                  src="https://player.vimeo.com/video/349625217"
               />
            </ResponsiveEmbed>
            <h4 className="videoText buttonTitle">VER PROGRAMA</h4>
         </div> */}
    </Container>
  );
};

export default GridHome;
