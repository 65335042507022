import React from 'react';
import { Row, Col, ResponsiveEmbed } from 'react-bootstrap';
import './VideoSection.scss';

import { Parallax } from 'react-scroll-parallax';
import { isMobile } from '../../../../layout/Layout';

export const VideoSection = (props) => {
  const mobile = isMobile();

  return (
    <Row className="videoSectionRow h-100">
      <Col
        xs={12}
        lg={6}
        className="videoCol"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h2 className="h2Title">
          “EL CULO ES LA
          <br />
          FUERZA DE LA MUJER”
        </h2>
        <p className="pText">by Olivia y Lucia</p>

        <Parallax
          className="custom-class"
          y={[20, -20]}
          tagOuter="figure"
          disabled={mobile}
        >
          <ResponsiveEmbed aspectRatio="16by9" className="responsiveEmbed">
            <iframe
              title="Twerkealo VSL"
              src="https://player.vimeo.com/video/453450245"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </ResponsiveEmbed>
        </Parallax>
      </Col>
    </Row>
  );
};

export default VideoSection;
