import React, { useEffect } from 'react';
import ArrowBack from '../../../components/arrowBack/ArrowBack';
import Layout from '../../../layout/Layout';

import './Terms.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { PoweredBySuprive } from '../../../components/powered/PoweredBySuprive';

export const TerminosYCondiciones = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Container fluid className="privacityContainer">
        <Row className="d-flex h-100" style={{ justifyContent: 'center' }}>
          <Col xs={12} lg={6} className="politicsTextStyle">
            <ArrowBack />
            <header>
              <h2>
                AVISO LEGAL-CONDICIONES USO-CONDICIONES DE CONTRATACI&Oacute;N
              </h2>
            </header>
            <div>
              <div>
                <p>
                  <span>
                    En este apartado se incluye informaci&oacute;n sobre las
                    condiciones de acceso y utilizaci&oacute;n de este sitio web
                    que deben ser conocidas por el usuario. Informaci&oacute;n
                    general sobre
                  </span>
                  <a href="http://www.suprive.com">
                    {' '}
                    <span>www.suprive.com</span>
                  </a>
                  <span>
                    {' '}
                    , a los efectos previstos en la Ley 34/2002 de Servicios de
                    la Sociedad de la Informaci&oacute;n y de Comercio
                    Electr&oacute;nico.
                  </span>
                </p>
                <h3 style={{ marginTop: '60px' }}>Aviso legal</h3>

                <p>
                  <span>
                    SUPRIVE MEDIA SL, est&aacute; domiciliada en la calle Gran
                    V&iacute;a Carlos III, 124 entr 1&ordm; 08034 Barcelona con
                    CIF
                  </span>
                  <span> B67480699.</span>
                </p>
                <p>
                  <span>El sitio web es propiedad de </span>
                  <span>SUPRIVE MEDIA SL.</span>
                </p>
                <p>
                  <span>
                    La utilizaci&oacute;n del sitio web atribuye la
                    condici&oacute;n de usuario/a y supone la aceptaci&oacute;n
                    de todas las condiciones incluidas en este aviso legal. El
                    hecho de acceder a la web y utilizar los materiales que
                    contiene implica que el usuario/a ha le&iacute;do y acepta,
                    sin reserva alguna, estas condiciones:
                  </span>
                </p>

                <h3 style={{ marginTop: '60px' }}>1. Condiciones de uso</h3>

                <p>
                  <span>
                    1.1. El sitio web contiene material preparado por Suprive
                    con fines &uacute;nicamente informativos. El usuario/a debe
                    tener en cuenta que este material puede que no refleje el
                    estado m&aacute;s reciente sobre las cuestiones analizadas.
                    Asimismo, este material puede ser modificado, desarrollado o
                    actualizado sin notificaci&oacute;n previa por parte de
                    Suprive.
                  </span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  <span>
                    1.2. El material contenido en el sitio web tiene la
                    finalidad de dar a conocer la actividad y los servicios que
                    presta Suprive. Trabajamos para que la informaci&oacute;n y
                    los servicios ofrecidos que se difunden cumplan la necesaria
                    integridad, veracidad, actualizaci&oacute;n, accesibilidad y
                    usabilidad. En este sentido, al consultar el sitio web se
                    debe tener presente la fecha de actualizaci&oacute;n que
                    consta en cada caso. La informaci&oacute;n que se puede
                    ofrecer a trav&eacute;s de este sitio web no sustituye en
                    ning&uacute;n caso la publicidad de las leyes, las
                    disposiciones generales y los actos administrativos que
                    deben ser publicados en peri&oacute;dicos, boletines
                    oficiales o en el sistema que Suprive determine.
                  </span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  <span>
                    1.3. Los enlaces que contiene el sitio web pueden conducir
                    al usuario/a a otros sitios, p&aacute;ginas web y/o
                    aplicaciones que est&eacute;n gestionados por terceros,
                    sobre los que Suprive no ejerce ning&uacute;n tipo de
                    control. Suprive no responde ni del contenido ni del estado
                    de dichos sitios, p&aacute;ginas web y aplicaciones, y
                    acceder a ellos a trav&eacute;s del sitio web o de la
                    aplicaci&oacute;n tampoco implica que Suprive recomiende o
                    apruebe su contenido.
                  </span>
                </p>
                <p>
                  <span>
                    En relaci&oacute;n con las im&aacute;genes de personas que
                    aparecen en el sitio web, Suprive las publica respetando el
                    derecho a la propia imagen. Estas im&aacute;genes se
                    utilizan exclusivamente con el fin de componer archivos o
                    v&iacute;deos gr&aacute;ficos elaborados para informar y dar
                    a conocer la actividad de Suprive. Pudi&eacute;ndose ceder a
                    terceras personas.
                  </span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  <span>
                    1.4. Suprive tiene implementadas las medidas de seguridad
                    adecuadas para mantener el sitio web y sus servidores libres
                    de cualquier programa maligno. El acceso seguro al sitio web
                    (como en el caso de las intranets) se hace mediante el
                    protocolo HTTPS.
                  </span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  <span>
                    1.5. Suprive, desde su sitio web mostrar&aacute; un
                    hiperv&iacute;nculo a sus perfiles de Facebook, Twitter,
                    YouTube, LinkedIn, Instagram, etc., con el objetivo de dar a
                    conocer las redes sociales en las que est&aacute; presente.
                    Una vez que se accede a cualquiera de estas redes sociales,
                    el usuario/a est&aacute; sometido a las pol&iacute;ticas que
                    cada servicio tenga establecidas.
                  </span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  <span>
                    1.6. Suprive permite recibir a trav&eacute;s de RRSS( redes
                    sociales) las noticias publicadas en su sitio web.
                  </span>
                </p>
                <p>RESPONSABILIDAD DERIVADA DEL USO DE LA WEB</p>
                <p>
                  <span>
                    SUPRIVE no se hace responsable, en ning&uacute;n caso, de
                    los da&ntilde;os y perjuicios de cualquier naturaleza que
                    pudieran ocasionar, a t&iacute;tulo enunciativo: errores u
                    omisiones en los contenidos, falta de disponibilidad del
                    portal o la transmisi&oacute;n de virus o programas
                    maliciosos o lesivos en los contenidos, a pesar de haber
                    adoptado todas las medidas tecnol&oacute;gicas necesarias
                    para evitarlo.
                  </span>
                </p>
                <p>
                  <span>
                    Desde el sitio web del cliente es posible que se redirija a
                    contenidos de terceros sitios web. Dado que el prestador no
                    puede controlar siempre los contenidos introducidos por los
                    terceros en sus sitios web, &eacute;ste no asume
                    ning&uacute;n tipo de responsabilidad respecto a dichos
                    contenidos. En todo caso, el prestador manifiesta que
                    proceder&aacute; a la retirada inmediata de cualquier
                    contenido que pudiera contravenir la legislaci&oacute;n
                    nacional o internacional, la moral o el orden
                    p&uacute;blico, procediendo a la retirada inmediata de la
                    redirecci&oacute;n a dicho sitio web, poniendo en
                    conocimiento de las autoridades competentes el contenido en
                    cuesti&oacute;n.
                  </span>
                </p>
                <p>
                  <span>
                    SUPRIVE no se hace responsable de la informaci&oacute;n y
                    contenidos almacenados, a t&iacute;tulo enunciativo, pero no
                    limitativo, en foros, chats, generadores de blogs,
                    comentarios, redes sociales o cualquier otro medio que
                    permita a terceros publicar contenidos de forma
                    independiente en la Web. No obstante, SUPRIVE se pone a
                    disposici&oacute;n de todos los usuarios/as, autoridades y
                    fuerzas de seguridad, y colaborando de forma activa en la
                    retirada o en su caso bloqueo de todos aquellos contenidos
                    que pudieran afectar o contravenir la legislaci&oacute;n
                    nacional, o internacional, derechos de terceros o la moral y
                    el orden p&uacute;blico. En caso de que el usuario considere
                    que existe en la Web alg&uacute;n contenido que pudiera ser
                    susceptible de esta clasificaci&oacute;n, se ruega lo
                    notifique de forma inmediata al administrador de la Web.
                  </span>
                </p>
                <p>
                  <span>
                    La Web ha sido revisada y probada para que funcione
                    correctamente. En principio, puede garantizarse el correcto
                    funcionamiento las 24 horas del d&iacute;a 7 d&iacute;as a
                    la semana, todo el a&ntilde;o. No obstante, SUPRIVE no
                    descarta la posibilidad de que existan ciertos errores de
                    programaci&oacute;n, o que acontezcan causas de fuerza
                    mayor, situaciones de pandemia sanitaria, cat&aacute;strofes
                    naturales, huelgas, o circunstancias semejantes que hagan
                    imposible el acceso a la Web.
                  </span>
                </p>
                <p>
                  <span>
                    Est&aacute; prohibida la introducci&oacute;n de hiperenlaces
                    con fines comerciales en p&aacute;ginas web ajenas a SUPRIVE
                    que permitan el acceso a la Web, sin el consentimiento
                    previo de SUPRIVE. SUPRIVE no se responsabiliza del uso o el
                    contenido de las p&aacute;ginas web de terceros que puedan
                    enlazar con su p&aacute;gina.
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>

                <h3 style={{ marginTop: '60px' }}>
                  2. Limitaci&oacute;n de responsabilidad derivada del uso de la
                  Web.
                </h3>

                <p>
                  2.1
                  <span>
                    SUPRIVE no se hace responsable, en ning&uacute;n caso, de
                    los da&ntilde;os y perjuicios de cualquier naturaleza que
                    pudieran ocasionar, a t&iacute;tulo enunciativo: errores u
                    omisiones en los contenidos, falta de disponibilidad del
                    portal o la transmisi&oacute;n de virus o programas
                    maliciosos o lesivos en los contenidos, a pesar de haber
                    adoptado todas las medidas tecnol&oacute;gicas necesarias
                    para evitarlo.
                  </span>
                </p>
                <p>
                  <span>
                    Desde el sitio web del cliente es posible que se redirija a
                    contenidos de terceros sitios web. Dado que el prestador no
                    puede controlar siempre los contenidos introducidos por los
                    terceros en sus sitios web, &eacute;ste no asume
                    ning&uacute;n tipo de responsabilidad respecto a dichos
                    contenidos. En todo caso, el prestador manifiesta que
                    proceder&aacute; a la retirada inmediata de cualquier
                    contenido que pudiera contravenir la legislaci&oacute;n
                    nacional o internacional, la moral o el orden
                    p&uacute;blico, procediendo a la retirada inmediata de la
                    redirecci&oacute;n a dicho sitio web, poniendo en
                    conocimiento de las autoridades competentes el contenido en
                    cuesti&oacute;n.
                  </span>
                </p>
                <p>
                  <span>
                    SUPRIVE no se hace responsable de la informaci&oacute;n y
                    contenidos almacenados, a t&iacute;tulo enunciativo, pero no
                    limitativo, en foros, chats, generadores de blogs,
                    comentarios, redes sociales o cualquier otro medio que
                    permita a terceros publicar contenidos de forma
                    independiente en la Web. No obstante, SUPRIVE se pone a
                    disposici&oacute;n de todos los usuarios/as, autoridades y
                    fuerzas de seguridad, y colaborando de forma activa en la
                    retirada o en su caso bloqueo de todos aquellos contenidos
                    que pudieran afectar o contravenir la legislaci&oacute;n
                    nacional, o internacional, derechos de terceros o la moral y
                    el orden p&uacute;blico. En caso de que el usuario considere
                    que existe en la Web alg&uacute;n contenido que pudiera ser
                    susceptible de esta clasificaci&oacute;n, se ruega lo
                    notifique de forma inmediata al administrador de la Web.
                  </span>
                </p>
                <p>
                  <span>
                    La Web ha sido revisada y probada para que funcione
                    correctamente. En principio, puede garantizarse el correcto
                    funcionamiento las 24 horas del d&iacute;a 7 d&iacute;as a
                    la semana, todo el a&ntilde;o. No obstante, SUPRIVE no
                    descarta la posibilidad de que existan ciertos errores de
                    programaci&oacute;n, o que acontezcan causas de fuerza
                    mayor, situaciones de pandemia sanitaria, cat&aacute;strofes
                    naturales, huelgas, o circunstancias semejantes que hagan
                    imposible el acceso a la Web.
                  </span>
                </p>
                <p>
                  <span>
                    Est&aacute; prohibida la introducci&oacute;n de hiperenlaces
                    con fines comerciales en p&aacute;ginas web ajenas a SUPRIVE
                    que permitan el acceso a la Web, sin el consentimiento
                    previo de SUPRIVE. SUPRIVE no se responsabiliza del uso o el
                    contenido de las p&aacute;ginas web de terceros que puedan
                    enlazar con su p&aacute;gina.
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  <span>
                    2.2. Quien utiliza la web, lo hace por su cuenta y riesgo.
                    Suprive no se responsabiliza de los errores u omisiones que
                    puedan afectar al contenido de esta u otros a los que se
                    pueda acceder a trav&eacute;s de esta. Suprive tampoco puede
                    ser considerada responsable de cualesquiera da&ntilde;os
                    derivados de la utilizaci&oacute;n de la web, ni por
                    cualquier actuaci&oacute;n realizada sobre la base de la
                    informaci&oacute;n que en ella se facilita.
                  </span>
                </p>
                <h3 style={{ marginTop: '60px' }}>
                  3. Protecci&oacute;n de datos
                </h3>
                <p>3.1. Datos recogidos mediante formularios</p>
                <ol>
                  <li>
                    <span>
                      El sitio web de Suprive contiene distintos formularios con
                      los que se pueden recoger datos de car&aacute;cter
                      personal. En todos estos formularios constan las
                      respectivas cl&aacute;usulas de informaci&oacute;n que dan
                      cumplimiento a lo previsto en la normativa de
                      protecci&oacute;n de datos.
                    </span>
                  </li>
                  <li>
                    <span>
                      Asimismo, y cuando es obligatorio por la normativa de
                      protecci&oacute;n de datos, tambi&eacute;n se solicita el
                      consentimiento de la persona afectada para tratar o ceder
                      sus datos de car&aacute;cter personal. A estos efectos se
                      tendr&aacute; en cuenta lo que se indica en cada
                      cl&aacute;usula.
                    </span>
                  </li>
                  <li>
                    <span>
                      En cualquier caso, Suprive solo trata los datos de
                      car&aacute;cter personal de los afectados que son
                      adecuados, pertinentes y no excesivos para cumplir las
                      finalidades determinadas, expl&iacute;citas y
                      leg&iacute;timas para las que se han obtenido. Asimismo,
                      garantiza la seguridad de los datos de car&aacute;cter
                      personal de los usuarios de la web mediante la
                      aplicaci&oacute;n de diferentes tipos de medidas de
                      seguridad de car&aacute;cter t&eacute;cnico y
                      organizativo.
                    </span>
                  </li>
                  <li>
                    <span>
                      Tal como prev&eacute; la normativa de protecci&oacute;n de
                      datos, en el caso de los menores de edad, solo los menores
                      de m&aacute;s de 14 a&ntilde;os podr&aacute;n proporcionar
                      datos de car&aacute;cter personal sin el consentimiento de
                      los padres o tutores. En el caso de los menores de 14
                      a&ntilde;os, se requerir&aacute; el consentimiento de los
                      padres o tutores. Suprive no asumir&aacute; ninguna
                      responsabilidad sobre los datos de car&aacute;cter
                      personal facilitados por los menores de 14 a&ntilde;os sin
                      el consentimiento de los padres o tutores.
                    </span>
                  </li>
                </ol>
                <p style={{ marginTop: '30px' }}>
                  3.2. Datos de transmisi&oacute;n
                </p>
                <p>
                  <span>
                    Los datos de transmisi&oacute;n est&aacute;n
                    impl&iacute;citos en el uso de los protocolos de
                    comunicaci&oacute;n de Internet. Los datos generados por la
                    navegaci&oacute;n en el sitio web de Suprive solo
                    ser&aacute;n tratados con fines estrictamente
                    t&eacute;cnicos y por el personal vinculado al deber de
                    secreto.
                  </span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  3.3.
                  <em>Cookies</em>
                </p>
                <p>
                  <span>
                    En virtud de lo dispuesto en el art&iacute;culo 22 de la
                    LSSI(
                  </span>
                  Ley 34/2002, de 11 de julio, de servicios de la sociedad de la
                  informaci&oacute;n y de comercio electr&oacute;nico
                  <span>
                    ), Suprive puede utilizar dispositivos de almacenamiento y
                    recuperaci&oacute;n de datos (
                  </span>
                  <em>
                    <span>cookies</span>
                  </em>
                  <span>
                    ) en equipos terminales de los destinatarios, a
                    condici&oacute;n de que los mismos hayan dado su
                    consentimiento despu&eacute;s de que se les haya facilitado
                    informaci&oacute;n clara y completa sobre su
                    utilizaci&oacute;n. Los usuarios podr&aacute;n obtener toda
                    la informaci&oacute;n sobre la finalidad de la
                    instalaci&oacute;n de las{' '}
                  </span>
                  <em>
                    <span>cookies</span>
                  </em>
                  <span>
                    {' '}
                    y los usos que se dar&aacute; a las mismas en la
                    pol&iacute;tica de{' '}
                  </span>
                  <em>
                    <span>cookies</span>
                  </em>
                  <span> de Suprive.</span>
                </p>
                <p>
                  <span>Suprive utiliza &uacute;nicamente las </span>
                  <em>
                    <span>cookies</span>
                  </em>
                  <span>
                    {' '}
                    que permiten que el usuario del sitio web navegue de una
                    manera m&aacute;s fluida e individualizada. (V&eacute;ase la{' '}
                  </span>
                  <a href="https://katsafarova.com/politica-de-cookies">
                    <span>pol&iacute;tica de cookies</span>
                  </a>
                  <span>.)</span>
                </p>
                <p>
                  <span>
                    Lo anterior no impedir&aacute; el posible almacenamiento o
                    acceso de &iacute;ndole t&eacute;cnica al solo fin de
                    efectuar la transmisi&oacute;n de una comunicaci&oacute;n
                    por una red de comunicaciones electr&oacute;nicas o, en la
                    medida que resulte estrictamente necesario, para prestar un
                    servicio de la sociedad de la informaci&oacute;n
                    expresamente solicitado por el destinatario.
                  </span>
                </p>
                <p>
                  <span>
                    Cuando se soliciten datos personales al usuario para la
                    prestaci&oacute;n de servicios, se le informar&aacute; del
                    car&aacute;cter necesario de los que se consideren
                    imprescindibles. Si no se facilitan dichos datos
                    obligatorios, no ser&aacute; posible la prestaci&oacute;n de
                    tales servicios. (V&eacute;ase la{' '}
                  </span>
                  <a href="https://katsafarova.com/politica-de-cookies">
                    <span>pol&iacute;tica de cookies</span>
                  </a>
                  <span>.)</span>
                </p>
                <p>
                  <span>&nbsp;</span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  3.4. Derechos de acceso, rectificaci&oacute;n,
                  cancelaci&oacute;n y oposici&oacute;n
                </p>
                <p>
                  <span>
                    El usuario puede ejercer los derechos de acceso,
                    rectificaci&oacute;n, cancelaci&oacute;n y oposici&oacute;n
                    mediante solicitud dirigida a la direcci&oacute;n{' '}
                  </span>
                  <span>dpo@suprive.com</span>{' '}
                  <span>
                    &nbsp;o por correo postal a la direcci&oacute;n que aparece
                    al inicio de este documento. En la solicitud se deben
                    indicar el nombre y apellidos del usuario, el domicilio a
                    efectos de notificaciones y el derecho que se ejerce,
                    adem&aacute;s de adjuntar una fotocopia del documento de
                    identidad o pasaporte.
                  </span>
                </p>
                <h3 style={{ marginTop: '60px' }}>4. Propiedad intelectual</h3>
                <p>
                  <span>
                    4.1. Todos los contenidos del sitio web, salvo que se
                    indique lo contrario, son titularidad exclusiva de Suprive o
                    de terceros, y con car&aacute;cter enunciativo y no
                    limitativo: dise&ntilde;o gr&aacute;fico, c&oacute;digo de
                    fuente, logotipos, textos, gr&aacute;ficos, ilustraciones,
                    fotograf&iacute;as y dem&aacute;s elementos que aparecen en
                    el sitio web. A este efecto se entienden como sitio web
                    todas aquellas creaciones incorporadas y expresadas por
                    cualquier medio o soporte tangible o intangible, conocido o
                    por conocer, que sea objeto de protecci&oacute;n por las
                    disposiciones normativas vigentes en materia de propiedad
                    intelectual o industrial.
                  </span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  <span>
                    4.2. Queda autorizada la mera visualizaci&oacute;n,
                    impresi&oacute;n, descarga o almacenamiento temporal, ya sea
                    en su totalidad o parcialmente, de los contenidos y de los
                    elementos insertados en el sitio web exclusivamente para su
                    uso personal, privado y no lucrativo por el usuario/a,
                    siempre que en todo caso se indiquen el origen y el autor/a
                    de los mismos y que, en su caso, aparezca el s&iacute;mbolo
                    del{' '}
                  </span>
                  <em>
                    <span>copyright</span>
                  </em>
                  <span>.</span>
                </p>
                <p style={{ marginTop: '30px' }}>
                  <span>
                    4.3. En ning&uacute;n caso el acceso al sitio web implica
                    ning&uacute;n tipo de permiso, renuncia, transmisi&oacute;n,
                    licencia o cesi&oacute;n total ni parcial de dichos derechos
                    por parte de sus titulares, salvo que se establezca
                    expresamente lo contrario. Asimismo, no confiere a los
                    usuarios ning&uacute;n otro derecho de utilizaci&oacute;n,
                    alteraci&oacute;n, explotaci&oacute;n, reproducci&oacute;n,
                    distribuci&oacute;n o comunicaci&oacute;n p&uacute;blica del
                    sitio web o de sus contenidos distintos a los previstos
                    aqu&iacute; expresamente.
                  </span>
                </p>
                <h3 style={{ marginTop: '60px' }}>
                  5. Legislaci&oacute;n aplicable y competencia jurisdiccional
                </h3>
                <p>
                  <span>
                    En el caso de que se produzca cualquier discrepancia o
                    controversia con motivo de la interpretaci&oacute;n o
                    aplicaci&oacute;n del presente aviso legal, Suprive y el
                    usuario se regir&aacute;n por la legislaci&oacute;n
                    espa&ntilde;ola y se someter&aacute;n a la
                    jurisdicci&oacute;n de los juzgados y tribunales de
                    Barcelona.
                  </span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <PoweredBySuprive />
      <br />
      <br />
    </Layout>
  );
};

export default TerminosYCondiciones;
