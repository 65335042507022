import React from 'react';
import { Container } from 'react-bootstrap';
import ForMeSection from '../../components/forMeSection/ForMeSection';
import './ForMe.scss';

export const ForMe = (props) => {
  return (
    <Container className="text-center RootForMe">
      <div className="RootForMe-inside h-100" style={{ zIndex: '90' }}>
        <ForMeSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default ForMe;
