import React from 'react';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
// FONT AWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//ICON
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

export const CustomToggle = (props) => {
  const { eventKey, activeEventKey, updateEventKey } = props;
  const toggleAction = useAccordionToggle(eventKey, () => {
    if (activeEventKey === eventKey) {
      updateEventKey(null);
    } else {
      updateEventKey(eventKey);
    }
  });

  return (
    <Accordion.Toggle
      as={Card.Header}
      eventKey={eventKey}
      onClick={toggleAction}
    >
      {eventKey === activeEventKey ? (
        <span className="courseContentIcon">
          <FontAwesomeIcon icon={faMinus} />
        </span>
      ) : (
        <span className="courseContentIcon">
          <FontAwesomeIcon icon={faPlus} />
        </span>
      )}
      {props.faqTitle}
    </Accordion.Toggle>
  );
};

export default CustomToggle;
