import React from 'react';
import { Container } from 'react-bootstrap';
import VideoSection from '../../components/videoSection/VideoSection';
import './Video.scss';

export const Video = (props) => {
  return (
    <Container className="text-center RootVideo" id="video">
      <div className="RootVideo-inside h-100" style={{ zIndex: '90' }}>
        <VideoSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Video;
