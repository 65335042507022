import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Navbar.scss';

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const CustomNavbar = () => {
  // NAV HIDDEN ON SCROLL //
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    if (currentScrollPos < 0 || prevScrollpos < 0) {
      // Negative scroll, do nothing!
      console.log('yeyy');
    } else if (prevScrollpos > currentScrollPos) {
      document.getElementById('navbar').style.top = '72px';
    } else {
      document.getElementById('navbar').style.top = '-450px';
      document.getElementById('brandLogoNav').style.width = '80%';
    }

    if (isMobile() && (currentScrollPos <= 0 || prevScrollpos <= 0)) {
      document.getElementById('navbar').style.height = '170px';
      // document.getElementById('containerNavToggle').style.height = '170px';
      document.getElementById('brandLogoNav').style.width = '100%';
      document.getElementById('brandLogoNav').style.height = '100px';
      document.getElementById('containerNavToggle').style.height = '100%';
      document.getElementById('brandLogoNav').style.top = '30px';
    } else if (!isMobile() && (currentScrollPos <= 0 || prevScrollpos < 0)) {
      document.getElementById('brandLogoNav').style.width = '100%';
      document.getElementById('navbarBrandContainer').style.height = 'auto';
      document.getElementById('brandLogoNav').style.top = '30px';
    } else if (isMobile() && currentScrollPos > 0) {
      document.getElementById('navbar').style.height = '70px';
      document.getElementById('brandLogoNav').style.height = '100%';
      document.getElementById('brandLogoNav').style.top = '0';
    } else if (!isMobile() && currentScrollPos > 0) {
      document.getElementById('navbar').style.height = '120px';
      // document.getElementById('navbar').style.top = '72px';

      document.getElementById('brandLogoNav').style.height = '100%';
      document.getElementById('containerNavToggle').style.height = '100%';
      document.getElementById('brandLogoNav').style.top = '0';
    }
    setUseWhiteNavbar(currentScrollPos > 50);
    prevScrollpos = currentScrollPos;
  };

  const [useWhiteNavbar, setUseWhiteNavbar] = useState(false);

  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      className={useWhiteNavbar ? 'nav-color-scroll' : 'nav-color-top'}
      variant="light"
      fixed="top"
      // style={{
      //   backgroundColor: useWhiteNavbar ? "white" : "transparent",
      // }}
    >
      <Navbar.Brand
        className="brandNameWhite"
        id="navbarBrandContainer"
        href="/"
      >
        <img
          alt=""
          src={require('../../assets/img/icons/Logo---Twerkealo.svg')}
          className="navBrandLogo"
          id="brandLogoNav"
        />
      </Navbar.Brand>

      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="ml-auto"
        id="containerNavToggle"
        style={{ outline: 'none' }}
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav
          className="navContent ml-auto"
          id={useWhiteNavbar ? 'navLinksBlack' : 'navLinksWhite'}
        >
          <Nav.Link href="#top" className="linksNav">
            INICIO
          </Nav.Link>
          <Nav.Link href="#beneficios" className="linksNav">
            BENEFICIOS
          </Nav.Link>
          <Nav.Link href="#programa" className="linksNav">
            PROGRAMA
          </Nav.Link>
          <Nav.Link href="#faq" className="linksNav">
            FAQ
          </Nav.Link>
          <Nav.Link
            href="https://sso.teachable.com/secure/731143/users/sign_in"
            className="linksNav"
          >
            Mis Cursos
          </Nav.Link>
          <Nav.Link href="#inscribirse" className="">
            <span className="curseLink buttonImage">ENTRAR AL CURSO</span>
          </Nav.Link>
        </Nav>
        {/* <Nav className="d-none d-lg-flex">
          <Nav.Link href="#inscribirse" className="curseLink">
            <span>Acceder al Curso</span>
          </Nav.Link>
        </Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
